import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Offcanvas,
  Modal,
  Nav,
  Navbar,
  Dropdown,
} from "react-bootstrap";
import { logoutUser } from "../redux/auth/actions";
import { CasinoProviders } from "../lib/data";
import { getUserBet } from "../redux/sports/actions";
import ToggleIcon from "../assets/images/icons/toggle-menu.webp";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import numberWithCommas from "../utils/numbersWithComma";
import Profile from "../assets/images/icons/profile-icon.webp";
import MyBets from "../assets/images/icons/my-bets-wht.png";
import Bonus from "../assets/images/icons/bonus-wht.png";
import Referral from "../assets/images/icons/referral-wht.png";
import Statement from "../assets/images/icons/statement-wht.png";
import SPMGames from "../assets/images/icons/spm-games.png";
import Logout from "../assets/images/icons/signout-wht.png";
import GamesSearchModal from "../views/components/GamesSearchModal";
import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";
import { VscVerifiedFilled } from "react-icons/vsc";

const navLinks = [
  { name: "All Live Games", href: "/home" },
  { name: "Ezugi", href: CasinoProviders["ezugi"]?.href },
  { name: "Evolution", href: CasinoProviders["evolution"]?.href },
  { name: "Slots", href: "/slots" },
  { name: "Aviator", href: "/casino/spribe/aviator" },
  { name: "Promotions", href: "javascript:void(0)" },
  { name: "Exchange", href: "/Exchange/cricket" },
  { name: "XPG", href: "/casino/xpg" },
  { name: "Vivo", href: "/casino/vivo" },
  { name: "Qtech", href: "/casino/qtech" },
  // { name: "Worldcasino", href: "/casino/worldcasino" },
  { name: "Supernowa", href: "/casino/supernowa" },
  // { name: "Sportsbook", href: "/betby" },
  // { name: "Sportsbook 2", href: "/sportsbook2" },
  // { name: "Kingmaker", href: "/kingmaker" },
  // { name: "Pragmaticplay", href: "/casino/pragmaticplay" },
  // { name: "Onlyplay", href: "/casino/onlyplay" },
];

const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { appDetails } = useSelector((state) => state.app);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const htmlElement = document.documentElement;

  useEffect(() => {
    loadNotifications();
    return () => {};
  }, []);

  useEffect(() => {
    if (showLeftbarToggle) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbar-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbar-opened");
    }
  }, [showLeftbarToggle]);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const handleToggleClick = () => {
    const realTimeValue = !showLeftbarToggle;
    if (realTimeValue) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbar-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbar-opened");
    }
    setShowLeftbarToggle(realTimeValue);
  };
  useEffect(() => {
    document.body.classList.remove("leftbar-opened");
    htmlElement.style.overflow = "auto";
    setShowLeftbarToggle(false);
  }, [location]);

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }} key={idx}>
                {notf?.content}
              </label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header aftrlgn">
        <div className="d-flex align-items-center">
          <div className="logo">
            <a href="/home">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
            </a>
          </div>

          <Navbar expand="md" className="d-none d-xl-block">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Nav.Link href="/home" className="logoImg d-md-none">
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                </Nav.Link>
                {navLinks.map((link, index) => (
                  <Nav.Link key={index} href={link.href}>
                    {link.name}
                  </Nav.Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>

        <div className="headerRight">
          <div className="balance-info">
            <ul>
              <li className="d-none d-md-block">
                Hello <span>{user?.mstruserid}</span>
              </li>
              <li>
                Balance:
                <span> {numberWithCommas(wallet?.balance || 0)} INR</span>
              </li>
              <li className="d-none d-md-block">
                Cashable Chip:
                <span> {numberWithCommas(wallet?.cash || 0)} INR</span>
              </li>
              <li className="d-none d-md-block">
                Non-Cash Chip:
                <span> {numberWithCommas(wallet?.coins)} INR</span>
              </li>
              <li className="deposit d-md-none">
                <a href="/gatewaylist">Deposit</a>
              </li>
            </ul>
          </div>

          {/* <Button
            variant="primary menu-btn"
            onClick={() => setShow(true)}
          >
            <img
              src={Profile}
              alt="my menu"
              style={{ width: "18px" }}
            />
          </Button> */}
          <Dropdown align="end" className="menu-dropdown">
            <Dropdown.Toggle variant="success" id="dropdown-menu">
              <img src={Profile} alt="my menu" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="balance d-md-none">
                <ul>
                  <li>
                    Hello <span>{user?.mstruserid}</span>
                  </li>
                  <li>
                    Cashable Chip:
                    <span> {numberWithCommas(wallet?.cash || 0)}</span>
                  </li>
                  <li>
                    Non-Cash Chip:
                    <span> {numberWithCommas(wallet?.coins)}</span>
                  </li>
                </ul>
              </div>
              <Dropdown.Item href="/profile">Profile</Dropdown.Item>
              <Dropdown.Item href="/gatewaylist">Deposit</Dropdown.Item>
              <Dropdown.Item href="/withdraw">Withdraw</Dropdown.Item>
              <Dropdown.Item href="/mybets">My Bets</Dropdown.Item>
              <Dropdown.Item href="/bonus">Bonus</Dropdown.Item>
              <Dropdown.Item href="/referral">Referral</Dropdown.Item>
              <Dropdown.Item href="/cashier">Account Statement</Dropdown.Item>
              <Dropdown.Item href="/" onClick={() => dispatch(logoutUser())}>
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <div className="leftbar_toggle ms-2 d-xl-none">
            <Button
              variant="primary"
              // onClick={() => setShowLeftbarToggle((s) => !s)}
              onClick={() => handleToggleClick()}
            >
              <img src={ToggleIcon} />
            </Button>
          </div>
        </div>

        <Offcanvas show={show} onHide={() => setShow(false)} placement={"end"}>
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <img src={Profile} alt="profile" style={{ width: "24px" }} />
                </div>
                <div className="user-info">
                  <span>{user?.mstruserid}</span>
                  {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
                  <a>{user?.mobileno}</a>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="top_sec">
              <div className="row">
                <div className="col-6">
                  <div className="balance mb-1">Main Balance</div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-1">
                    {numberWithCommas(wallet?.cash || 0)}
                  </div>
                </div>
                <div className="col-6">
                  <div className="balance mb-3">Bonus</div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-3">
                    {numberWithCommas(wallet?.coins) || 0}
                  </div>
                </div>
                <div className="col-6">
                  <a className="btn btn-secondary w-100" href="withdraw">
                    Withdraw
                  </a>
                </div>
                <div className="col-6">
                  <a className="btn btn-primary w-100" href="/gatewaylist">
                    Deposit
                  </a>
                </div>
                {/* <div className="col-4">
                <Button variant="primary w-100" onClick={handleShowModal}>
                  Exchange
                </Button>
                <ExchangeFundsFormPopUp
                  showexchangeModal={showexchangeModal}
                  handleCloseModal={handleCloseModal}
                />
              </div> */}
              </div>
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <img src={Profile} alt="profile" />
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <img src={MyBets} alt="my bets" />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/bonus">
                  <img src={Bonus} alt="bonus" />
                  <span>Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral">
                  <img src={Referral} alt="referral" />

                  <span>Referral</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <img src={Statement} alt="account statement" />
                  <span>Account Statement</span>
                </a>
              </li>
              {/* <li>
                <a href="#">
                  <SettingIcon />
                  <span>Transfer Funds ( For Excahnge)</span>
                </a>
              </li> */}
              {/* <li>
                <a href="/settings">
                  <SettingIcon />
                  <span>Settings</span>
                </a>
              </li> */}
              {/* <li>
                <a href="/spmgames">
                  <img src={SPMGames} alt="SPM games" />
                  <span>SPM Games</span>
                </a>
              </li> */}
              {/* <li>
                <a href="/kyc">
                  <VscVerifiedFilled />
                  <span>KYC</span>
                </a>
              </li> */}
              <li onClick={() => dispatch(logoutUser())}>
                <a href="/">
                  <img src={Logout} alt="logout" />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
            <ul className="social_links">
              <li>
                <a target="_blank" href={`${appDetails?.TELEGRAM}`}>
                  <TelegramIcon />
                </a>
              </li>
              {/* <li>
              <div
                onClick={() =>
                  window.open(https://wa.me/+917888846060, "_blank")
                }
              >
                <WhatsappIcon />
              </div>
            </li> */}
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <WhatsappIcon />
                </a>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
