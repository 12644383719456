import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import { CasinoProviders } from "../lib/data";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
import Instagram from "../assets/images/Instagram.svg";
import Aviator from "../assets/images/games-icon/Aviator.png";
import Ezugi from "../assets/images/games-icon/headicon/ezugi.png";
import Sports from "../assets/images/games-icon/headicon/sports.png";
import Evolution from "../assets/images/games-icon/headicon/evolution.png";
import HomeIcon from "../assets/images/games-icon/headicon/house.png";

import EvolutionPrvdr from "../assets/images/providers/evolution-prvdr.png";
import EzugiPrvdr from "../assets/images/providers/ezugi-prvdr.png";

import PhonePe from "../assets/images/payments/phone_pe-payment.png";
import GooglePe from "../assets/images/payments/google_pe-payment.png";
import AxisBank from "../assets/images/payments/axis_bank-payment.png";
import IndusindBank from "../assets/images/payments/indusind_bank-payment.png";
import YesBank from "../assets/images/payments/yes_bank-payment.png";
import IDFCBank from "../assets/images/payments/idfc_bank-payment.png";

import Age18Plus from "../assets/images/18_plus.png";
import PhoneIcon from "../assets/images/phone-icon.png";

const footerPages = [
  "home",
  "sportsbook",
  "casino/evolution",
  "casino/ezugi",
  "gatewaylist",
];

const Footer = () => {
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  useEffect(() => {
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <footer className="footer">
      <div className="inner-content">
        <div className="our-game">
          <h4>Our Game Provider</h4>
          <div className="provider-img">
            <img src={EvolutionPrvdr} alt="evolution" />
            <img src={EzugiPrvdr} alt="ezugi" />
          </div>
        </div>

        <div className="footer-links">
          <ul>
            <li>
              <a href="/terms">Terms and Conditions</a>
            </li>
            <li>
              <a href="/privacypolicy ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegame">Responsible Gaming</a>
            </li>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
            <li>
              <a href="/fairplay">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrule">Games Rules</a>
            </li>
          </ul>
        </div>

        <div className="availble-payment">
          <h4>Available Payment Methods</h4>
          <div className="payment-methods">
            <img src={PhonePe} alt="Phone Pe" />
            <img src={GooglePe} alt="Google Pe" />
            <img src={AxisBank} alt="Axis Bank" />
            <img src={IndusindBank} alt="Indusind Bank" />
            <img src={YesBank} alt="Yes Bank" />
            <img src={IDFCBank} alt="IDFC Bank" />
          </div>
        </div>

        <div className="social-networks">
          <h4>Social networks</h4>
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                {" "}
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src="/images/facebook-512.svg" alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>

        <div class="footer-copyright">
          <img src={appDetails?.LOGO_URL} alt="logo" className="logo" />
          <img src={Age18Plus} alt="age 18 plus" />
          <p>
            Gaming is the fun and Excitable Entertainment. Gambling is an
            attractive option if you want to try your Luck. Most of Casino
            visitors enjoy this kind of entertainment without any problems, but
            there are a small number of people who lose a control over their
            gambling activity. Play Responsibly.
          </p>
        </div>

        <p>{appDetails?.FOOTER_TEXT || ""}</p>

        {/* <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a
                className={activePage === "sportsbook" ? "active" : ""}
                href="/spmsports/cricket"
              >
                <img src={Sports} alt="sports" />
                <span>Sports</span>
              </a>
            </li>
            <li>
              <a href={CasinoProviders["evolution"]?.href}>
                <img src={Evolution} alt="evolution" />
                <span>Evolution</span>
              </a>
            </li>
            <li>
              <a className={activePage === "home" ? "active" : ""} href="/home">
                <img src={HomeIcon} alt="home" />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a href={CasinoProviders["ezugi"]?.href}>
                <img src={Ezugi} alt="ezugi" />
                <span>Ezugi</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "gatewaylist" ? "active" : ""}
                href="/gatewaylist"
              >
                <img src={Aviator} alt="aviator" />
                <span>Deposit</span>
              </a>
            </li>
          </ul>
        </div> */}
      </div>

      <a href={`tel://${appDetails?.PHONE}`} className="contact-phone">
        <div className="icon-box">
          <img src={PhoneIcon} alt="phone" />
        </div>
      </a>
    </footer>
  );
};

export default Footer;
