import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Nav, Navbar } from "react-bootstrap";
import ToggleIcon from "../assets/images/icons/toggle-menu.webp";
import GamesSearchModal from "../views/components/GamesSearchModal";
import ModalWrapper from "./ModalWrapper";
import { APP_CONST } from "../config/const";

const navLinks = [
  { name: "All Live Games", action: APP_CONST.AUTH_MODAL.LOGIN },
  { name: "Ezugi", action: APP_CONST.AUTH_MODAL.LOGIN },
  { name: "Evolution", action: APP_CONST.AUTH_MODAL.LOGIN },
  { name: "Slots", action: APP_CONST.AUTH_MODAL.LOGIN },
  { name: "Aviator", action: APP_CONST.AUTH_MODAL.LOGIN },
  { name: "Promotions", action: APP_CONST.AUTH_MODAL.LOGIN },
  { name: "Exchange", action: APP_CONST.AUTH_MODAL.LOGIN },
  { name: "XPG", action: APP_CONST.AUTH_MODAL.LOGIN },
  { name: "Vivo", action: APP_CONST.AUTH_MODAL.LOGIN },
  { name: "Qtech", action: APP_CONST.AUTH_MODAL.LOGIN },
  // { name: "Worldcasino", action: APP_CONST.AUTH_MODAL.LOGIN },
  { name: "Supernowa", action: APP_CONST.AUTH_MODAL.LOGIN },
  // { name: "Sportsbook", action: APP_CONST.AUTH_MODAL.LOGIN },
  // { name: "Sportsbook 2", action: APP_CONST.AUTH_MODAL.LOGIN },
  // { name: "Kingmaker", action: APP_CONST.AUTH_MODAL.LOGIN },
  // { name: "Pragmaticplay", action: APP_CONST.AUTH_MODAL.LOGIN },
  // { name: "Onlyplay", action: APP_CONST.AUTH_MODAL.LOGIN },
];

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { appDetails } = useSelector((state) => state.app);
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const htmlElement = document.documentElement;
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");
  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };
  const handleToggleClick = () => {
    const realTimeValue = !showLeftbarToggle;
    if (realTimeValue) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbar-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbar-opened");
    }
    setShowLeftbarToggle(realTimeValue);
  };
  useEffect(() => {
    document.body.classList.remove("leftbar-opened");
    htmlElement.style.overflow = "auto";
    setShowLeftbarToggle(false);
  }, [location]);

  return (
    <>
      <header className="header beforeheader">
        <div className="d-flex align-items-center">
          <div className="logo">
            <a href="/">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
            </a>
          </div>

          <Navbar expand="md" className="d-none d-xl-block">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {navLinks.map((link, index) => (
                  <Nav.Link
                    key={index}
                    href="javascript:void(0)"
                    onClick={() => {
                      handleModalShow(link.action);
                    }}
                  >
                    {link.name}
                  </Nav.Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>

        <div className="headerRight">
          <Button
            variant="primary"
            onClick={() => {
              handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
            }}
            className="button-primary btn_signin"
          >
            Login
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
            }}
            className="button-primary btn_signup btn-secondary ms-2"
          >
            Register
          </Button>

          <div className="leftbar_toggle ms-2 d-xl-none">
            <Button variant="primary" onClick={() => handleToggleClick()}>
              <img src={ToggleIcon} />
            </Button>
          </div>
        </div>
      </header>

      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          setShowAuthModals={setShowAuthModals}
        />
      )}
    </>
  );
};

export default Header;
