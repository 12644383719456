import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { loginUser, resetPassword } from "../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { Container, Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import hide from "../../../assets/svg/hide.png";
import EyeIcon from "../../../assets/svg/EyeIcon";
import { APP_CONST } from "../../../config/const";

import { useNavigate } from "react-router-dom";
import { Toaster } from "../../../utils/toaster";

const ResetPassword = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [otp, setOtpValue] = useState("");
  const [password, setPassword] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = () => {
    if (otp == "" || password == "") {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "All fields are mandatory");
    } else {
      const payload = {
        otp,
        password,
      };

      resetPassword(payload)
        .then((res) => {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Password reset success");
          // navigate("/sign-in");
          handleClose();
          handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
        })
        .catch((err) => {
          Toaster(
            APP_CONST.TOAST_TYPE.ERROR,
            err.response.data.message || "Request failed"
          );
        });
    }
  };

  return (
    <Modal
      show={show}
      className={"steps-canvas lgn-reg-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
      >
        ×
      </button>
      <Modal.Body>
        <Modal.Title>Reset Password</Modal.Title>
        <div className="login-section">
          <div className="form-steps">
            <Form>
              <Form.Group className="form-group" controlId="formBasicOTP">
                <Form.Control
                  type="text"
                  placeholder="Enter OTP*"
                  onChange={(e) => setOtpValue(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            </Form>

            <div className="modal-btn">
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={isVerifying}
              >
                {!isVerifying ? "SUBMIT" : "SUBMITTING"}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ResetPassword;
