import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import {
  checkIfUserNameExist,
  mobileVerification,
  registerUser,
  sendOtpToMobile,
} from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { APP_CONST } from "../../../config/const";

import { FiEye, FiEyeOff } from "react-icons/fi";

const RegisterModal = ({
  show,
  handleClose,
  handleModalShow,
  agentCode: agentCodeProp,
}) => {
  const { appDetails } = useSelector((state) => state.app);
  const validationMessages = [
    { text: "at least 8 symbols", key: 1 },
    { text: "at least 1 digit (0-9)", key: 2 },
    { text: "at least 1 lowercase characters (a-z)", key: 3 },
    { text: "at least 1 uppercase characters (A-Z)", key: 4 },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPasswordValidtions, setShowPasswordValidations] = useState(false);
  const [validationIndex, setValidationIndex] = useState([]);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [referalCode, setReferalCode] = useState(location?.state?.referalCode);
  const [isReffered, setIsReffered] = useState(false);
  const [cookies, setCookie] = useCookies(["refferal"]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [agentCode, setAgentCode] = useState(agentCodeProp);
  const [agentCookies, setAgentCookie] = useCookies(["agentCode"]);

  const [mobileDetails, setMobileDetails] = useState({});

  const [userNameConfig, setUserNameConfig] = useState({
    isUserNameExist: false,
    message: "User Name is available",
  });

  const getMobileDetails = () => {
    const details =
      localStorage.getItem("mobile_details") &&
      JSON.parse(localStorage.getItem("mobile_details"));
    if (details) {
      setMobileDetails(details);
    }
  };
  useEffect(() => {
    getMobileDetails();
  }, []);
  const checkForPasswordValidations = (e) => {
    const password = e.target.value;
    let fulfilledindex = [];
    if (password.length >= 8) {
      fulfilledindex.push(1);
    }
    if (/\d/.test(password)) {
      fulfilledindex.push(2);
    }
    const smallAlp = new RegExp("(?=.*[a-z])");
    if (smallAlp.test(password)) {
      fulfilledindex.push(3);
    }
    const capAlp = new RegExp("(?=.*[A-Z])");
    if (capAlp.test(password)) {
      fulfilledindex.push(4);
    }
    setValidationIndex(fulfilledindex);
  };

  useEffect(() => {
    if (referalCode) {
      setCookie("code", referalCode);
      setReferalCode(referalCode);
      setIsReffered(true);
    } else if (cookies?.code) {
      setReferalCode(cookies.code);
      setIsReffered(true);
    }
  }, [cookies, referalCode]);

  useEffect(() => {
    if (agentCode) {
      setAgentCookie("agent", agentCode);
    } else if (agentCookies?.agent) {
      setAgentCode(agentCookies?.agent);
    }
  }, [agentCookies, agentCode]);

  const validateData = (data = null) => {
    const dataToValidate = data ? data : formData;
    const { name, username, password, confirmPassword } = dataToValidate;
    const newErrors = {};
    if (!name || name === "") {
      newErrors.name = "Name cannot be empty";
    }
    if (!username || username === "") {
      newErrors.userName = "User name cannot be empty";
    }
    if (username && (username.length < 4 || username.length > 15)) {
      newErrors.userName = "Username must be 4 to 15 characters.";
    }
    if (!/^[A-Za-z0-9 ]+$/.test(username)) {
      newErrors.userName = "Special characters are not allowed in username.";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    if (password && (password.length < 8 || password.length > 20)) {
      newErrors.password = "Password must be 8 to 20 characters.";
    }
    if (!confirmPassword || confirmPassword === "") {
      newErrors.confirmPassword = "Confirm password cannot be empty";
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = "Password and Confirm password must be same";
    }
    setErrors(newErrors);
    return newErrors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorsData = validateData();

    if (Object.keys(errorsData).length > 0) {
      //setErrors(errorsData);
    } else {
      let dataToSend = { ...formData };
      delete dataToSend.confirmPassword;
      if (agentCode) {
        dataToSend.agent_code = agentCode;
      }
      dispatch(
        registerUser(
          {
            ...dataToSend,
            typeId: 3,
            parentId: 81026,
            mobile: mobileDetails?.mobile,
            country_code: mobileDetails.country_code,
          },
          navigate
        )
      );
      // checkIfExistingUserDetails(formData);
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
    validateData(tempFormData);
  };

  return (
    <Modal
      show={show}
      className={"steps-canvas lgn-reg-modal register"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
      >
        ×
      </button>

      <Modal.Body>
        <div className="login-section">
          <Modal.Title className="mb-4">Sign up</Modal.Title>
          <div className="form-steps">
            <Form>
              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Control
                  type={"text"}
                  name="name"
                  placeholder="Name*"
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Control
                  type="text"
                  name="username"
                  placeholder="Username*"
                  onChange={handleChange}
                  isInvalid={!!errors.userName}
                  // onBlur={checkUserName}
                />

                <Form.Control.Feedback type="invalid">
                  {errors?.userName}
                </Form.Control.Feedback>
              </Form.Group>
              <div
                className="user_avail"
                style={
                  userNameConfig.isUserNameExist
                    ? { color: "#bc2f3e", fontSize: "12px" }
                    : { color: "#198754", fontSize: "12px" }
                }
              >
                {formData?.username && userNameConfig.message}
              </div>
              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password*"
                  autoComplete="new-password"
                  onChange={(e) => {
                    handleChange(e);
                    checkForPasswordValidations(e);
                  }}
                  isInvalid={!!errors.password}
                  onFocus={() => {
                    setShowPasswordValidations(true);
                  }}
                />
                <span
                  className="icon"
                  onClick={() => setShowPassword((p) => !p)}
                >
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </span>
                <Form.Control.Feedback type="invalid">
                  {errors?.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="confirm password*"
                  onChange={handleChange}
                  isInvalid={!!errors.confirmPassword}
                  onFocus={() => {
                    setShowPasswordValidations(true);
                  }}
                />
                <span
                  className="icon"
                  onClick={() => setShowConfirmPassword((p) => !p)}
                >
                  {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
                </span>
                <Form.Control.Feedback type="invalid">
                  {errors?.confirmPassword}
                </Form.Control.Feedback>
                {showPasswordValidtions && (
                  <div className="password-note">
                    <ul>
                      {validationMessages.map((item, index) => {
                        const result = validationIndex.find(
                          (i) => i === item.key
                        );
                        return (
                          <li
                            className={result ? "pwd-validation-message" : ""}
                          >
                            {item?.text}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </Form.Group>

              <div className="modal-btn">
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Register
                </Button>
              </div>
            </Form>

            <div className="login">
              <span>Already have an account? </span>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                  handleClose(APP_CONST.AUTH_MODAL.REGISTER);
                }}
              >
                Log In
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default RegisterModal;
