import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  checkIfDemoUserExist,
  demoLogin,
  forgotPassword,
  loginUser,
  resetPassword,
} from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Dropdown, Form } from "react-bootstrap";

import { FiEye, FiEyeOff } from "react-icons/fi";
import { APP_CONST } from "../../../config/const";

import PhoneInput from "react-phone-input-2";
import { Toaster } from "../../../utils/toaster";
import { parsePhoneNumber } from "libphonenumber-js";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [mobileNumber, setMobileNumber] = useState("");
  const [changeModalContent, setChangeModalContent] = useState(true);
  const [otp, setOtpValue] = useState("");
  const [password, setPassword] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isDemoUserExist, setIsDemoUserExist] = useState(false);

  useEffect(() => {
    checkDemoUser();
  }, []);

  const checkDemoUser = async () => {
    const response = await checkIfDemoUserExist();
    if (response && response?.data?.error === false) {
      setIsDemoUserExist(response?.data?.data?.hasDemoUsers === true);
    }
  };
  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };

      dispatch(loginUser(loginData));
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };

  const handleSubmitForgotPass = async (e) => {
    e.preventDefault();
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.phone = phoneNumber.nationalNumber;
      payload.countryCode = phoneNumber.countryCallingCode;
      forgotPassword(payload)
        .then((res) => {
          if (res.data.error === false) {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP sent");
            setIsOtpSent(true);
          }
        })
        .catch((err) => {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    } else {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "Please enter mobile number");
    }
  };

  const handleSubmitResetPass = () => {
    setIsVerifying(true);

    if (otp == "" || password == "") {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "All fields are mandatory");
    } else {
      const payload = {
        otp,
        password,
      };

      resetPassword(payload)
        .then((res) => {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Password reset success");

          setChangeModalContent((prev) => !prev);
        })
        .catch((err) => {
          Toaster(
            APP_CONST.TOAST_TYPE.ERROR,
            err.response.data.message || "Request failed"
          );
          setIsVerifying(false);
        });
    }
  };

  const loginDemoUser = (e) => {
    e.preventDefault();
    const payload = {
      username: "Demo101betwin",
      password: "Abcd@1234",
    };
    dispatch(loginUser(payload));
  };

  return (
    <Modal
      show={show}
      id="loginModal"
      className={"steps-canvas lgn-reg-modal login"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
      >
        ×
      </button>
      <Modal.Body>
        {changeModalContent ? (
          <div
            className={
              !changeModalContent ? `login-section` : `login-section active`
            }
          >
            <Modal.Title>Log in. It is fast and easy.</Modal.Title>
            <div className="form-steps">
              <Form>
                <Form.Group className="form-group" controlId="formBasicName">
                  <Form.Control
                    type="text"
                    placeholder="Username *"
                    name="username"
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password *"
                    name="password"
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowPassword((s) => !s)}
                  >
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="d-flex align-items-center justify-content-between my-2">
                  <div class="remember_check">
                    <input
                      type="checkbox"
                      name="remember_me"
                      id="remember_me"
                      value="1"
                    />
                    <label for="remember_me">Remember me</label>
                  </div>
                  <div className="frgt-pwd">
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        setChangeModalContent((prev) => !prev);
                      }}
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>

                <div className="modal-btn">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Log In
                  </Button>
                </div>
              </Form>

              <div className="register">
                <span>Still no account? </span>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
                    handleClose(APP_CONST.AUTH_MODAL.LOGIN);
                  }}
                >
                  Register
                </a>
              </div>

              {isDemoUserExist && (
                <>
                  <div className="mt-4 mb-1">
                    Need Demo? Just Click the button below.
                  </div>
                  <div className=" ">
                    <Button
                      variant="primary"
                      className="mt-1"
                      onClick={loginDemoUser}
                    >
                      Demo Login
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div
            className={
              changeModalContent
                ? `login-section`
                : `login-section ForgotPasswordSection`
            }
          >
            <Modal.Title>Password recovery</Modal.Title>
            <div className="form-steps">
              <Form onSubmit={handleSubmitForgotPass}>
                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <PhoneInput
                    country={"in"}
                    countryCodeEditable={false}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    // onlyCountries={["in"]}
                    inputStyle={{
                      width: "100%",
                      padding: "0.7rem 0.75rem",
                      paddingLeft: "50px",
                    }}
                    onChange={(phone) => {
                      // alert("phone", phone);
                      // alert(phone);
                      setMobileNumber(phone);
                    }}
                  />
                </Form.Group>

                <div className="modal-btn">
                  <Button
                    variant="primary"
                    type="submit"
                    // onClick={handleSubmitForgotPass}
                  >
                    Send OTP
                  </Button>
                </div>

                <Form.Group className="form-group mt-4" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP*"
                    onChange={(e) => setOtpValue(e.target.value)}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group className="form-group" controlId="">
                  <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                </Form.Group>

                <div className="modal-btn">
                  <Button
                    variant="primary"
                    onClick={handleSubmitResetPass}
                    disabled={!isOtpSent}
                  >
                    {!isVerifying ? "Submit" : "Submitting..."}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
